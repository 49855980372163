<template>
  <div class="w-md-400">
    <div class="d-flex justify-content-between mt-1">
      <router-link
        :to="{ name: 'sirh_dashboard' }"
        class="rounded p-3 text-center d-flex flex-column align-items-center cursor-pointer"
        tag="div"
        style="width: 8rem;"
        :active-class="activeClass"
      >
        <base-icon
          name="activity"
          width="34"
          height="34"
          class="m-0 mb-1"
        ></base-icon>
        <small>
          <span>Tableau de bord</span>
        </small>
      </router-link>

      <router-link
        :to="{ name: 'sirh_recherche' }"
        class="rounded p-3 text-center d-flex flex-column align-items-center cursor-pointer"
        tag="div"
        style="width: 8rem;"
        :active-class="activeClass"
      >
        <base-icon
          name="search"
          width="34"
          height="34"
          class="m-0 mb-1"
        ></base-icon>
        <small>
          <span>Recherche avancée</span>
        </small>
      </router-link>

      <router-link
        :to="{ name: 'sirh_plan' }"
        class="rounded p-3 text-center d-flex flex-column align-items-center cursor-pointer"
        tag="div"
        style="width: 8rem;"
        :active-class="activeClass"
      >
        <base-icon
          name="calendar"
          width="34"
          height="34"
          class="m-0 mb-1"
        ></base-icon>
        <small>
          <span>Plan de formations</span>
        </small>
      </router-link>
    </div>
    <!-- ------------------------------------ -->
    <!-- SEARCH -->
    <!-- ------------------------------------ -->
    <div class="pl-3 pr-3 pb-3">
      <input-search
        text="Rechercher..."
        @reset="selectItem"
        @search="search"
        v-model="searchText"
        :showResults="false"
        :loading="loadingSearch"
        :modeIOS="true"
      ></input-search>
    </div>
    <!-- ------------------------------------ -->
    <div v-if="!loading">
      <div class>
        <h5 class="m-0 pl-3 pb-2" :class="qwarkBorder" v-if="!searchText">
          Récents
          <span class="text-muted">
            <small>- liste des derniers entrants</small>
          </span>
        </h5>

        <div class="collaborateurs-liste" v-if="!searchText">
          <!-- NEW -->
          <div
            class="d-flex flex-row align-items-center"
            v-for="collaborateur in collaborateurs"
            :key="'NEW_' + collaborateur.id"
          >
            <liste-item
              :collaborateur="collaborateur"
              v-if="collaborateur.collaborateur_new"
              :avatar="true"
            ></liste-item>
          </div>
          <!-- NOT NEW -->
          <div
            class="d-flex flex-row align-items-center"
            v-for="collaborateur in collaborateurs"
            :key="'OLD_' + collaborateur.id"
          >
            <liste-item
              :collaborateur="collaborateur"
              v-if="!collaborateur.collaborateur_new"
              :avatar="true"
            ></liste-item>
          </div>
        </div>
      </div>
      <!-- SEARCH -->
      <h5
        class="m-0 pl-3 pb-2"
        :class="qwarkBorder"
        v-if="searchText && data.length > 0"
      >
        Recherche
        <span class="text-muted">
          <small>- résultats de la recherche</small>
        </span>
      </h5>
      <div
        class="d-flex flex-row align-items-center"
        v-for="i in data"
        :key="i.id"
      >
        <liste-item :collaborateur="i" :infos="false"></liste-item>
      </div>
    </div>
    <div v-show="loading">
      <div class="spinner-grow text-primary" role="status"></div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import ListeItem from "@/components/collaborateurs/liste/ListeItem.vue";
import InputSearch from "@/components/bases/InputSearch.vue";
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: {
    ListeItem,
    InputSearch,
    BaseIcon
  },

  data() {
    return {
      //collaborateurs: {},
      data: {},
      searchMode: false,
      searchText: "",
      loading: false,
      loadingSearch: false
    };
  },
  created() {},
  methods: {
    search: function() {
      if (this.searchText && this.searchText.length > 0) {
        this.loadingSearch = true;
        this.$http
          .get("collaborateurs/search/" + this.searchText)
          .then(response => (this.data = response.data))
          .catch(error => console.log(error))
          .finally(() => (this.loadingSearch = false));
      }
    },
    selectItem: function() {
      this.searchText = null;
      this.data = {};
    }
  },
  computed: {
    ...mapMultiRowFields("collaborateurs", ["collaborateurs"]),
    qwarkBorder: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
    activeClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },
  }
};
</script>
<style lang="css">
@media (min-width: 992px) {
  .w-md-400 {
    width: 390px;
  }

  .collaborateurs-liste {
    max-height: 100%;
    overflow: auto;
  }
}
</style>
