<template>
  <container-page>
    <base-bloc class="flex-shrink-0 mb-3" :style="styleLeftBarLg">
      <collaborateur-liste></collaborateur-liste>
    </base-bloc>
    <div class="flex-grow-1 mb-3">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </container-page>
</template>
<script>
import Liste from "@/components/collaborateurs/liste/Liste.vue";
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import ContainerPage from "@/components/containers/ContainerPage.vue";
import { mapActions } from "vuex";
import screenWidth from "@/mixins/screenWidth";

export default {
  components: {
    ContainerPage,
    "collaborateur-liste": Liste,
    "base-bloc": BlocSimple
  },
  mixins: [screenWidth],
  methods: {
    ...mapActions({
      getCollaborateursAll: "collaborateurs/getCollaborateursAll",
      getCollaborateurs: "collaborateurs/getCollaborateurs",
      getData: "collaborateurs/getData"
    })
  },
  mounted() {
    this.getCollaborateurs();
    this.getData();
  },
  computed: {
    styleLeftBarLg() {
      if (this.w > 992)
        return "min-width:350px;max-height:100vh;overflow:auto;position:sticky;top:0;z-index:1020;";
      else return "max-height:50vh;overflow:auto;";
    }
  }
};
</script>
