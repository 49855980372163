<template>
  <router-link
    :to="{ name: 'sirh_collaborateur', params: { id: collaborateur.id } }"
    class="d-flex align-items-center hover-item w-100 p-2 pl-3 pr-3 cursor-pointer"
    :class="[hoverClass, qwarkBorder]"
    :active-class="activeClass"
    tag="div"
    @click.native="$emit()"
  >
    <AppAvatar :url="collaborateur.collaborateur_avatar" propSize="3rem" ref="avatar"></AppAvatar>
    <div class="w-100">
      <div class="ml-3 w-100" style="line-height: 1.4;">
        <div class="d-flex align-items-center">
          <span class="text-truncate">
            {{ collaborateur.collaborateur_nom }}
            {{ collaborateur.collaborateur_prenom }}
          </span>
          <span class="ml-2 badge badge-primary" v-if="collaborateur.collaborateur_new">Nouveau</span>
        </div>
        <div style>
          <contrat-viewer class="text-truncate" :contrat="contrat" :mode="1"></contrat-viewer>
        </div>
      </div>
    </div>

    <div>
      <BaseIcon
        name="circle"
        width="10"
        height="10"
        class="text-success m-auto"
        fill="#28a745"
        v-if="collaborateur.collaborateur_actif"
      ></BaseIcon>
      <BaseIcon
        name="circle"
        width="10"
        height="10"
        class="text-danger m-auto"
        fill="#dc3545"
        v-if="!collaborateur.collaborateur_actif"
      ></BaseIcon>
    </div>
  </router-link>
</template>
<script>
import AppAvatar from "@/components/bases/Avatar.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import ContratViewer from "@/components/collaborateurs/viewers/ContratViewer.vue";

export default {
  components: { AppAvatar, BaseIcon, ContratViewer },
  props: {
    collaborateur: {
      Type: Object,
      requierd: true,
      default: {},
    },
    avatar: {
      Type: Boolean,
      requierd: false,
      default: false,
    },
    infos: {
      Type: Boolean,
      requierd: false,
      default: true,
    },
  },
  computed: {
    activeClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "active-item-light text-danger";
      } else {
        return "active-item-dark text-warning";
      }
    },
    hoverClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
    qwarkBorder: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
    contrat: function () {
      return this.collaborateur.collaborateur_contrats;
    },
  },
};
</script>
<style lang="css">
.hover-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.active-item-light {
  background-color: rgba(0, 0, 0, 0.05);
}
.active-item-dark {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
